import { $$ } from './util';

const routeHooks = {
  galerie: {
    onEnter() {
      $$('.carousel__img[loading="lazy"]').forEach(img => {
        img.removeAttribute('loading');
      });
    },
  },
};

export default routeHooks;
