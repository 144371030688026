import { $$ } from './util';

const KEY =
  'U3VwcGVuaHVobiB1bmQgRmxlaXNjaHNhbGF0LCBqYSBkYXMgc2NobWVja3QgbWlyIGluIGRlciBUYXQu';

const ENCODED_MAIL_HREF =
  'NTYlODIlNjMlMjclMjMlNDAlMTA4JTUlMTklNDElNDYlNiUxNyU0MSU0MiU2NSU3OSUzMCUzNyUyMSU1NSUxJTIzJTI3JTc5JTYwJTQzJTg1JTE4JTYxJTQ0JTIlNyUzNiU1MCUxMyUxMyU0NSUzNiUzJTU2JTUyJTM5';

const createDecoder = key => {
  const delim = '%';
  return encodedStr => {
    const codeListStr = atob(encodedStr);
    const codeList = codeListStr.split(delim).map(Number);
    const charList = codeList.map((code, i) => {
      const keyCode = key.charAt(i % key.length).charCodeAt(0);
      // eslint-disable-next-line no-bitwise
      const charCode = code ^ keyCode;
      return String.fromCharCode(charCode);
    });
    return charList.join('');
  };
};

const decode = createDecoder(KEY);

const mailTags = $$('._m-link');

mailTags.forEach(mail => {
  // eslint-disable-next-line no-param-reassign
  mail.addEventListener('click', e => {
    e.preventDefault();
    window.location.href = decode(ENCODED_MAIL_HREF);
  });
});
