import routeHooks from './routeHooks';
import { $$, $ } from './util';

const SECTION_ACTIVE_CLASS = 'section--active';
const PREV_SECTION_CLASS = 'section--prev';
const LINK_ACTIVE_CLASS = 'nav__link--active';
const PREV_LINK_CLASS = 'nav__link--prev';
const SECTION_ID_PREFIX = 'anchor-';
const DEFAULT_PAGE_ID = 'start';
const baseTitle = (() => {
  const title = $('[data-base-title]');
  return title && title.dataset && title.dataset.baseTitle;
})();

function extractId(url) {
  if (!url) {
    return undefined;
  }
  const [, id] = url.split('#');
  return id;
}

function selectItemList({
  baseClass,
  activeClass,
  prevClass,
  id,
  getId,
}) {
  $$(baseClass).forEach(item => {
    const cl = item.classList;
    cl.remove(prevClass);
    if (cl.contains(activeClass)) {
      cl.add(prevClass);
    }
    cl.remove(activeClass);
    if (getId(item) === id) {
      cl.add(activeClass);
      if (item.dataset && item.dataset.pageTitle) {
        document.title = `${baseTitle} - ${item.dataset.pageTitle}`;
      }
    }
  });
}

function selectId(id) {
  if (id in routeHooks) {
    routeHooks[id].onEnter();
  }
  [
    {
      baseClass: '.section',
      activeClass: SECTION_ACTIVE_CLASS,
      prevClass: PREV_SECTION_CLASS,
      id,
      getId: item => item.id.split(SECTION_ID_PREFIX)[1],
    },
    {
      baseClass: '.nav__link',
      activeClass: LINK_ACTIVE_CLASS,
      prevClass: PREV_LINK_CLASS,
      id,
      getId: item => extractId(item.href),
    },
  ].forEach(selectItemList);
}

$$('.nav__link').forEach(link => {
  link.addEventListener('click', e => {
    const id = extractId(e.target.href);
    window.location.hash = id;
    selectId(id);
    return false;
  });
});

selectId(extractId(window.location.href) || DEFAULT_PAGE_ID);

function closeMobileNav() {
  $('.banner__header__toggle').checked = false;
}

closeMobileNav();

$$('.nav__link').forEach(link =>
  link.addEventListener('click', () => {
    closeMobileNav();
    window.scrollTo(0, 0);
  }),
);
