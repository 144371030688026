/* eslint-disable no-use-before-define */
import { $, $$, cl } from './util';

const CAROUSEL_CLASS = 'carousel';
const BUTTON_CLASS = 'carousel__button';
const IMG_CLASS = 'carousel__img';
const CAROUSEL_LEFT_CLASS = 'carousel__button--left';
const CAROUSEL_RIGHT_CLASS = 'carousel__button--right';

const setArrowHeight = () => {
  setTimeout(() => {
    const img = $(cl(IMG_CLASS));
    const { height } = img.getBoundingClientRect();
    $$(cl(BUTTON_CLASS)).forEach(button => {
      const { height: buttonHeight } = button.getBoundingClientRect();
      // eslint-disable-next-line no-param-reassign
      button.style.top = `${height / 2 - buttonHeight / 2}px`;
    });
  }, 0);
};

function isOnGaleryPage() {
  return window.location.hash.indexOf('galerie') !== -1;
}

let activeImg = 0;

function scroll() {
  const left = $$(cl(IMG_CLASS))
    .filter((x, i) => i < activeImg)
    .reduce((acc, img) => acc + img.getBoundingClientRect().width, 0);
  $(cl(CAROUSEL_CLASS)).scrollTo({ left, behavior: 'smooth' });
}

export function stepLeft() {
  const imgLength = $$(cl(IMG_CLASS)).length;
  activeImg = ((activeImg || imgLength) - 1) % imgLength;
  scroll();
}

export function stepRight() {
  const imgLength = $$(cl(IMG_CLASS)).length;
  activeImg = (activeImg + 1) % imgLength;
  scroll();
}

function imgKeyListener(e) {
  if (!isOnGaleryPage()) {
    return;
  }
  if (e.key === 'ArrowLeft' || e.key === 'Left') {
    stepLeft();
  }
  if (e.key === 'ArrowRight' || e.key === 'Right') {
    stepRight();
  }
}

function addKeyBoardListeners() {
  window.addEventListener('keydown', imgKeyListener);
}

$$(cl(IMG_CLASS)).forEach(img => {
  img.addEventListener('resize', setArrowHeight);
  img.addEventListener('load', setArrowHeight);
});

window.addEventListener('click', setArrowHeight);

$(cl(CAROUSEL_LEFT_CLASS)).addEventListener('click', stepLeft);
$(cl(CAROUSEL_RIGHT_CLASS)).addEventListener('click', stepRight);

addKeyBoardListeners();
