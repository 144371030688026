export const $ = document.querySelector.bind(document);
export function $$(...args) {
  const res = document.querySelectorAll(...args);
  return Array.from(res);
}

export function isIE() {
  const ua = navigator.userAgent;
  return ua.indexOf('MSIE') !== -1 || ua.indexOf('Trident') !== -1;
}

export function cl(className) {
  return `.${className}`;
}

export function isMobileView() {
  return window.innerWidth <= 670;
}

export function hasWebpSupport(callback) {
  const img = new Image();
  const cb = () => callback(img.height === 2);
  img.onload = cb;
  img.onerror = cb;
  img.src =
    'data:image/webp;base64,UklGRjoAAABXRUJQVlA4IC4AAACyAgCdASoCAAIALmk0mk0iIiIiIgBoSygABc6WWgAA/veff/0PP8bA//LwYAAA';
}
