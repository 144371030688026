/* eslint-disable no-use-before-define */
import PinchZoom from 'pinch-zoom-js';
import {
  stepLeft as parentStepLeft,
  stepRight as parentStepRight,
} from './galery';
import { $, $$, cl, isMobileView, hasWebpSupport } from './util';

const IMG_CLASS = 'carousel__img';
const MODAL_IMG_CLASS = 'modal__img';
const MODAL_IMG_ACTIVE_CLASS = 'modal__img--active';
const MODAL_BG_CLASS = 'modal-bg';
const MODAL_BG_ACTIVE_CLASS = 'modal-bg--active';
const MODAL_CLOSE_CLASS = 'modal__close-button';
const MODAL_CAROUSEL = 'modal__carousel';
const MODAL_LEFT_CLASS = 'modal__button--left';
const MODAL_RIGHT_CLASS = 'modal__button--right';
const PINCH_CLASS = 'pinch-zoom-container';

let pinch = null;
let activeImg = 0;
let supportsWebp = false;

hasWebpSupport(hasSupport => {
  supportsWebp = hasSupport;
});

function withOptimalExt(src) {
  if (supportsWebp) {
    const srcParts = src.split('.');
    srcParts.pop();
    srcParts.push('webp');
    return srcParts.join('.');
  }
  return src;
}

function stepLeft() {
  closeImg();
  const imgLength = $$(cl(IMG_CLASS)).length;
  openImg(((activeImg || imgLength) - 1) % imgLength);
}

function stepRight() {
  closeImg();
  openImg((activeImg + 1) % $$(cl(IMG_CLASS)).length);
}

function imgKeyListener(e) {
  if (e.key === 'Escape') {
    closeImg();
  }
  if (e.key === 'ArrowLeft') {
    stepLeft();
  }
  if (e.key === 'ArrowRight') {
    stepRight();
  }
}

function addKeyBoardListeners() {
  window.addEventListener('keydown', imgKeyListener);
}

function removeKeyBoardListeners() {
  window.removeEventListener('keydown', imgKeyListener);
}

function openImg(i) {
  if (!isMobileView()) {
    return;
  }
  addKeyBoardListeners();
  activeImg = i;
  $(cl(MODAL_BG_CLASS)).classList.add(MODAL_BG_ACTIVE_CLASS);
  const img = $$(cl(IMG_CLASS))[i];
  const view = $(cl(MODAL_CAROUSEL));
  const largeImg = document.createElement('img');
  largeImg.src = withOptimalExt(img.src);
  largeImg.alt = img.alt;
  largeImg.classList.add(MODAL_IMG_CLASS);
  largeImg.classList.add(MODAL_IMG_ACTIVE_CLASS);
  view.appendChild(largeImg);
  pinch = new PinchZoom(largeImg);
}

function closeImg() {
  removeKeyBoardListeners();
  $(cl(MODAL_BG_CLASS)).classList.remove(MODAL_BG_ACTIVE_CLASS);
  if (pinch) {
    pinch.disable();
  }
  $$(cl(PINCH_CLASS)).forEach(container => container.remove());
}

$$(cl(IMG_CLASS)).forEach((img, i) => {
  img.addEventListener('click', () => openImg(i));
});

$(cl(MODAL_CLOSE_CLASS)).addEventListener('click', closeImg);

$(cl(MODAL_LEFT_CLASS)).addEventListener('click', () => {
  stepLeft();
  parentStepLeft();
});
$(cl(MODAL_RIGHT_CLASS)).addEventListener('click', () => {
  stepRight();
  parentStepRight();
});
