/*! licenses are at files/license.txt */
import 'element-scroll-polyfill';
// eslint-disable-next-line import/no-unresolved, import/extensions
import swFileName from 'resources:sw.js';
// eslint-disable-next-line import/no-unresolved
import production from 'consts:production';
// eslint-disable-next-line import/no-unresolved
import unregisterSWs from 'consts:unregisterSWs';
import './mail';
import './nav';
import './galery';
import './modalGalery';
import { $, isIE } from './util';

const NOT_IE_CLASS = 'not-ie';

if (!isIE()) {
  $('.main').classList.add(NOT_IE_CLASS);
}

if ('serviceWorker' in navigator) {
  if (production) {
    navigator.serviceWorker
      .register(`/${swFileName}`, { scope: '/' })
      .catch(error => {
        // eslint-disable-next-line no-console
        console.log(
          `ServiceWorker registration failed with ${error}`,
        );
      });
  } else if (unregisterSWs) {
    // Unregister all SWs in dev mode
    navigator.serviceWorker
      .getRegistrations()
      .then(registeredSWs =>
        registeredSWs.forEach(sw => sw.unregister()),
      );
  }
}
